import React from 'react';
import HomeIndex from '../Home/index';
import Login from '../Authentication/login';
import SSOLogin from '../Authentication/ssoLogin';
import Register from '../Authentication/register';
import Dashboard from '../Dashboard/index';
import Socialpost from '../SocialPost/composepost';
import MyCalendar from '../Calendar/calendar';
import Index from '../Inbox';
import ImportLinkedPages from '../Linkedin/importLinkedPages';
import Publishpost from '../Reportpublished/publishedpost';
import Report from '../Reportpublished/report';
import Adsummary from '../Reportpublished/adsummary'
// home page
import Privacy from "../Home/privacy";
import Terms from "../Home/terms";
import Faq from "../Home/faq";
import Adsreport from '../Adsreport/listview';
import Readinbox from '../ReadInbox/readinboxmessage';
// manage account and post
import ManageAccountPage from '../Manageaccount/managepostaccount';
import ViewAccountPost from '../Manageaccount/viewAccountPost';
import SocialTimeline from '../Timeline/socialtimeline';
import Myaccount from '../Myaccount/myaccount';
import ViewPostDetails from '../Manageaccount/viewPostDetails';
import Socialclientlead from '../SocialMedialead/socialclientlead';
import Socialads from '../SocialMediaAds/socialads_view';
import Socialmediaadlisting from '../SocialMediaAds/socialmediaadlisting_view';
import Fbadlisting from '../SocialMediaAds/fbadlisting_view';
import Mediatype from '../MediaType/mediatype';
import Roles from '../Roles/list_role';
import Add_roles from '../Roles/add_role';
import EditRole from '../Roles/editRole';
import Budgetlist from '../MediaType/budgetlist';
import Budgetlisting from '../MediaType/budgetlisting';
import BudgetlistView from '../MediaType/budget_list_view';
import Add_user from '../Add_user/add_user';
import ListUsers from '../Add_user/list_user';
import EditUser from '../Add_user/edit_user';
import Leadssocial from '../Leads/leads';
import Fbadsadbuget from '../SocialMediaBudget/fbadsadbuget_view';
// import Linkedinadsadbuget from '../SocialMediaBudget/linkedinadsadbuget_view';



const homeRoutes = [
    { path: '/', element: <HomeIndex /> },
    { path: '/privacy_policy', element: <Privacy /> },
    { path: '/terms_conditions', element: <Terms /> },
    { path: '/faq', element: <Faq /> }
];

const publicRoutes = [
    { path: '/login', element: <Login /> },
    { path: '/register', element: <Register /> }
];

const ssoRoutes = [
    { path: '/sso-login/:apiUserToken', element: <SSOLogin /> }
];

const authRoutes = [
    { path: '/dashboard', name:'Dashboard',element: <Dashboard /> },
    { path: '/compose-post', name:'Socialpost',element: <Socialpost /> },
    { path: '/calendar', name:'MyCalendar',element: <MyCalendar /> },
    { path: '/inbox', name:'Index',element: <Index /> },
    { path: '/import-linkedin-pages', name:'ImportLinkedPages',element: <ImportLinkedPages /> },
    { path: '/publish-post', name:'Publishpost',element: <Publishpost /> },
    { path: '/publish-report',name:'Adsummary', element: <Adsummary /> },
    { path: '/ads-report',name:'Adsreport', element: <Adsreport /> },
    { path: '/readinbox', name:'Readinbox',element: <Readinbox /> },
    { path: '/manage-account', name:'ManageAccountPage',element: <ManageAccountPage /> },
    { path: '/view-account-post/:enPageAccountId/:enMediaId', name:'ViewAccountPost',element: <ViewAccountPost /> },
    { path: '/socialtimeline/:enParentcid/:enSmid/:enCommentTitle', name:'SocialTimeline',element: <SocialTimeline /> },
    { path: '/myaccount', name:'Myaccount',element: <Myaccount /> },
    { path: '/socialclientlead', name:'Socialclientlead',element: <Socialclientlead/>},
    { path: '/socialmediaads', name:'Socialads',element: <Socialads/>},
    { path: '/socialmediaadlisting',name:'Socialmediaadlisting', element: <Socialmediaadlisting/>},    
    { path: '/media-type', name:'Mediatype',element: <Mediatype/>},
    { path: '/roles', name:'Roles',element:<Roles/>},
    { path: '/add_role', name:'Add_roles',element:<Add_roles/>},
    { path: '/fbadlisting', name:'Fbadlisting',element: <Fbadlisting/>},  
    { path: '/budget-list', name:'Budgetlist',element: <Budgetlist/>},
    { path: '/budgetlisting', name:'Budgetlisting',element: <Budgetlisting/>},
    { path: '/edit-role/:enRoleid',name:'edit-role',element: <EditRole />},
    { path: '/budgectlistview/:enbudgetid', name:'Budgectlistview', element: <BudgetlistView/>},
    { path: '/add_user', name:'Add_user',element:<Add_user/>},
    { path: '/list_user' ,name :'ListUser',element:<ListUsers />},
    { path: '/edit-user/:enUserid' ,name :'EditUser',element:<EditUser />},
    { path: '/leads', name:'Leadssocial', element:<Leadssocial/>},
    { path: '/fbadsadbuget', name:'Fbadsadbuget',element: <Fbadsadbuget/>},
    // { path: '/linkedinadsadbuget', name:'Linkedinadsadbuget',element: <Linkedinadsadbuget/>}
    // { path: '/statusview', name:'Statusview',element: <Statusview /> }
];


export { homeRoutes, publicRoutes, ssoRoutes, authRoutes };
