import { Button, Grid, TextField, Typography } from "@mui/material"
import React, { useState, useEffect } from "react";
import styleCss from "../Style/style"
import SocialIcon from '../img/facebook_media.png'
import LinkedIcon from '../img/linkedin_media.png'
import Xicon from '../img/xicon_media.png'
import GoogleIcon from '../img/googleicon_media.png'
import WhatsupIcon from '../img/whatsupicon_media.png'
import MessageIcon1 from '../img/messageicon_media1.png'
import MessageIcon2 from '../img/messageicon_media2.png'
import MessageIcon3 from '../img/messageicon_media3.png'
import MessageIcon4 from '../img/messageicon_media4.png'
import Box from '@mui/material/Box';

import FacebookDropdown from '../img/dropdown-faceicon.png'
import LinkedinDropdown from '../img/LinkedinDropdown.png'
import XiconDropdown from '../img/XiconDropdown.png'
//import SliderRange from "./slider"
import FacebookCampaign from "./facebookCampaign"
import LinkedInCampaign from "./linkedInCampaign"
import TwitterCampaign from "./twitterCampaign"
import GoogleCampaign from "./googleCampaign"
import WhatsupCampaign from "./whatsupCampaign"
import MailCampaign from "./mailCampaign"
import MessageCampaign from "./messageCampaign"
import VoicebotCampaign from "./voicebotCampaign"
import IvrCampaign from "./ivrCampaign"
import WorldMap from "./map"
import { authpostAxios } from "../Utilities/commonAxios";
import { Formik, Form, Field, useFormik, ErrorMessage } from 'formik';
import SliderRange from '@mui/material/Slider';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import '../Style/Loader.css'
import dayjs from 'dayjs'; // Import Dayjs
import 'dayjs/locale/en';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import VoiceIcon from '../img/messageicon_media3.png'
import VoiceChatIcon from '@mui/icons-material/VoiceChat';
import { Link, useNavigate } from "react-router-dom";
import { authAxios } from "../Utilities/commonAxios";
import { useDispatch } from 'react-redux';


const MIN = 100;
const MAX = 100000;
const initialValues = {
    fb_campagin_name: '',
    wb_campagin_name: '',
    mail_campagin_name: '',
    sms_campagin_name: '',
    voicebot_campagin_name: '',
    ivr_campagin_name: '',
    total_amount: 0,
    facebook_budget: MIN,
    facebook_text_value: MIN,
    whatsapp_cost: 0.85,
    sms_cost: 0.15,
    mail_cost: 0.25,
    voice_cost: 2.25,
    ivr_cost: 2.50,
    whatsapp_budget: MIN,
    whatsapp_text_value: MIN,
    mail_budget: MIN,
    mail_text_value: MIN,
    sms_budget: MIN,
    sms_text_value: MIN,
    voice_bot_budget: MIN,
    voice_bot_text_value: MIN,
    ivr_budget: MIN,
    ivr_text_value: MIN,
    mediaTypes: [],
    from_date: dayjs(),
    to_date: dayjs().add(2, 'day'),
    is_whatsapp_checked: 0,
    is_mail_checked: 0,
    is_messageunread_checked: 0,
    is_fb_checked: 0,
    is_vicebot_checked: 0,
    is_ivr_checked: 0
};
const validationSchema = Yup.object({
});

const Mediatype = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [isFacebookSelected, setIsFacebookSelected] = useState(false)
    const [isLinkedSelected, setIsLinkedSelected] = useState(false)
    const [isTwitterSelected, setIsTwitterSelected] = useState(false)
    const [isGoogleSelected, setIsGoogleSelected] = useState(false)
    const [isWhatsupSelected, setIsWhatsupSelected] = useState(false)
    const [isMailSelected, setIsMailSelected] = useState(false)
    const [isMessageunreadSelected, setIsMessageunreadSelected] = useState(false)
    const [isvoicebotSelected, setisvoicebotSelected] = useState(false)
    const [is_ivr_Selected, setis_ivr_Selected] = useState(false)

    const [isFormSubmitted, setIsFormSubmitted] = useState(false)
    const [showAdditionalInput, setShowAdditionalInput] = useState(false);
    const [additionalInputValue, setAdditionalInputValue] = useState('');

    const [value, setValue] = React.useState('');
    const [selectedOptionText, setSelectedOptionText] = useState('');
    const handleChange = (event) => {
        console.log(event.target.value);
        setValue(event.target.value);
        const selectedText = event.target.options[event.target.selectedIndex].text;
        setSelectedOptionText(selectedText);
    };


    const handleMediaType = (mediaType) => {
        let updatedMediaTypes = [...submitSocialForm.values.mediaTypes];
        let isChecked = 0;

        switch (mediaType) {
            case 'facebook':
                isChecked = isFacebookSelected ? 0 : 1;
                setIsFacebookSelected(!isFacebookSelected);
                if (!isFacebookSelected) updatedMediaTypes.push('facebook');
                else updatedMediaTypes = updatedMediaTypes.filter(type => type !== 'facebook');
                submitSocialForm.setFieldValue('is_fb_checked', isChecked);
                break;
            case 'whatsup':
                isChecked = isWhatsupSelected ? 0 : 1;
                setIsWhatsupSelected(!isWhatsupSelected);
                if (!isWhatsupSelected) updatedMediaTypes.push('whatsup');
                else updatedMediaTypes = updatedMediaTypes.filter(type => type !== 'whatsup');
                submitSocialForm.setFieldValue('is_whatsapp_checked', isChecked);
                break;
            case 'mail':
                isChecked = isMailSelected ? 0 : 1;
                setIsMailSelected(!isMailSelected);
                if (!isMailSelected) updatedMediaTypes.push('mail');
                else updatedMediaTypes = updatedMediaTypes.filter(type => type !== 'mail');
                submitSocialForm.setFieldValue('is_mail_checked', isChecked);
                break;
            case 'messageunread':
                isChecked = isMessageunreadSelected ? 0 : 1;
                setIsMessageunreadSelected(!isMessageunreadSelected);
                if (!isMessageunreadSelected) updatedMediaTypes.push('messageunread');
                else updatedMediaTypes = updatedMediaTypes.filter(type => type !== 'messageunread');
                submitSocialForm.setFieldValue('is_messageunread_checked', isChecked);
                break;
            case 'voicebot':
                isChecked = isvoicebotSelected ? 0 : 1;
                setisvoicebotSelected(!isvoicebotSelected);
                if (!isvoicebotSelected) updatedMediaTypes.push('voicebot');
                else updatedMediaTypes = updatedMediaTypes.filter(type => type !== 'voicebot');
                submitSocialForm.setFieldValue('is_vicebot_checked', isChecked);
                break;
            case 'ivr':
                isChecked = is_ivr_Selected ? 0 : 1;
                setis_ivr_Selected(!is_ivr_Selected);
                if (!is_ivr_Selected) updatedMediaTypes.push('ivr');
                else updatedMediaTypes = updatedMediaTypes.filter(type => type !== 'ivr');
                submitSocialForm.setFieldValue('is_ivr_checked', isChecked);
                break;
            default:
                break;
        }

        submitSocialForm.setFieldValue('mediaTypes', updatedMediaTypes);
    };



    const [fbcampaign, setFbcampaign] = useState([])
      const getfbcampaignFromAPI = async () => {
        await authAxios(
            {
                url: `${process.env.REACT_APP_BACKENDURL}api/get-fb-budget-estimation`,
                data: "",
            },
            dispatch
        ).then((response) => {
            if (response?.data?.flag == 1) {
                setFbcampaign(response?.data?.data);
            } else {
                setFbcampaign([]);
            }

        });
    };

    console.log(fbcampaign);

    /*
    const [socialfbcampignlist, setSocialfbcampignlist] = useState([]);
    const getsocialfbcampignlistFromAPI = async () => {
        try {
            const response = await authpostAxios({
                url: `${process.env.REACT_APP_BACKENDURL}api/get-fb-campaign-list`,
                data: {}
            });
            setSocialfbcampignlist(response.data?.data || []);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    };
    */

    /*
    const [socialfbcampigndetaillist, setSocialfbcampigndetaillist] = useState([]);
    const getfbcampaigndetaillist = async (facebook_ad_posting_id) => {
        try {
            const response = await authpostAxios({
                url: `${process.env.REACT_APP_BACKENDURL}api/get_budget_fb-ads_campaign_list`,
                data: { fb_campaign_id: facebook_ad_posting_id }
            });
            setSocialfbcampigndetaillist(response.data?.data || []);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    };
    */

    const [socialfbcampigndetaillist, setSocialfbcampigndetaillist] = useState([]);
    const getfbcampaigndetaillist = async (facebook_ad_posting_id) => {
        try {
            const response = await authpostAxios({
                url: `${process.env.REACT_APP_BACKENDURL}api/get-fb-budget-estimation-listing`,
                data: { facebook_ad_posting_id: facebook_ad_posting_id }
            });
            setSocialfbcampigndetaillist(response.data?.data || []);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    };




    const handleFormSubmit = () => {
        Swal.fire({
            icon: 'question',
            title: 'Confirm Submission',
            text: 'Are you sure you want to save the data?',
            showCancelButton: true,
            confirmButtonText: 'Yes, save it!',
            cancelButtonText: 'No, cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                setShowAdditionalInput(true); // Show the additional input field and button
            }
        });
    };
  
    const handleSaveAdditionalInput = () => {
        // Add the additional input value to form values
        submitSocialForm.setFieldValue('additional_input', additionalInputValue);
        submitSocialForm.submitForm(); // Trigger form submission
    };

    const handleDateChangeto = (newDate) => {
        submitSocialForm.setFieldValue('to_date', newDate); // Update the form field value
    };

    const handleDateChangefrom = (newDate) => {
        submitSocialForm.setFieldValue('from_date', newDate); // Update the form field value
    };

    const submitSocialForm = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, { resetForm }) => {
            setLoading(true);
            Swal.fire({
                icon: 'question',
                title: 'Confirm Submission',
                text: 'Are you sure you want to save the budget screen?',
                showCancelButton: true,
                confirmButtonText: 'Yes, save it!',
                cancelButtonText: 'No, cancel',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    // User confirmed, proceed with form submission

                    console.log(values);
                    try {
                        let formData = {
                            fb_campagin_name: values.fb_campagin_name,
                            wb_campagin_name: values.wb_campagin_name,
                            mail_campagin_name: values.mail_campagin_name,
                            sms_campagin_name: values.sms_campagin_name,
                            ivr_campagin_name: values.ivr_campagin_name,
                            voicebot_campagin_name: values.voicebot_campagin_name,

                            whatsapp_cost: values.whatsapp_cost,
                            sms_cost: values.sms_cost,
                            mail_cost: values.mail_cost,
                            whatsapp_budget: values.whatsapp_budget,
                            voice_cost: values.voice_cost,
                            ivr_cost: values.ivr_cost,


                            total_amount: values.total_amount,
                            facebook_budget: values.facebook_budget,
                            facebook_text_value: values.facebook_text_value,

                            whatsapp_budget: values.whatsapp_budget,
                            whatsapp_text_value: values.whatsapp_text_value,

                            mail_budget: values.mail_budget,
                            mail_text_value: values.mail_text_value,

                            sms_budget: values.sms_budget,
                            sms_text_value: values.sms_text_value,

                            ivr_budget: values.ivr_budget,
                            ivr_text_value: values.ivr_text_value,

                            voice_bot_budget: values.voice_bot_budget,
                            voice_bot_text_value: values.voice_bot_text_value,

                            ivr_budget: values.ivr_budget,
                            ivr_text_value: values.ivr_text_value,

                            from_date: values.from_date,
                            to_date: values.to_date,
                            budget_amount: values.budget_amount,

                            mediaTypes: values.mediaTypes,
                            budget_tracker_name: values.additional_input,

                            is_fb_checked: values.is_fb_checked,
                            is_whatsapp_checked: values.is_whatsapp_checked,
                            is_mail_checked: values.is_mail_checked,
                            is_messageunread_checked: values.is_messageunread_checked,
                            is_vicebot_checked: values.is_vicebot_checked,
                            is_ivr_checked: values.is_ivr_checked,

                        };

                        let response = await authpostAxios({
                            url: `${process.env.REACT_APP_BACKENDURL}api/budget-tracker`,
                            data: formData,
                        });
                        console.log(response.data);
                        if (response.data.flag === 1) {
                            setLoading(false);
                            Swal.fire({
                                icon: 'success',
                                title: 'Form Submitted Successfully!',
                                text: 'Your data has been submitted.',
                            }).then(() => {
                                resetForm();
                                navigate('/budgetlisting');
                            });
                            
                        } else {
                            // Handle other cases if needed
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Failed to submit the form. Please try again later.',
                            });
                        }
                    } catch (error) {
                        console.error('Error submitting form:', error);
                        // Handle error accordingly
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Failed to submit the form. Please try again later.',
                        });
                    }
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    // User cancelled, do nothing or provide feedback
                    Swal.fire({
                        icon: 'info',
                        title: 'Cancelled',
                        text: 'The form submission was cancelled.',
                    });
                }
            });
        },
    });



    const handleTotalAmountChange = (event, newValue) => {

        const totalChecked = [
            isFacebookSelected,
            isLinkedSelected,
            isTwitterSelected,
            isGoogleSelected,
            isWhatsupSelected,
            isMailSelected,
            isMessageunreadSelected
        ].filter(item => item).length;


        const distributedAmount = newValue / totalChecked;
        console.log(distributedAmount);

        submitSocialForm.setFieldValue('total_amount', newValue)

        if (isFacebookSelected) {
            submitSocialForm.setFieldValue('facebook_amount', submitSocialForm.values.facebook_amount + distributedAmount);
        }
    };

    useEffect(() => {
        console.log('------------------------------------------------');
        console.log(submitSocialForm.values.fb_campagin_name);
        console.log('------------------------------------------------');
        if (submitSocialForm.values.fb_campagin_name) {
            getfbcampaigndetaillist(submitSocialForm.values.fb_campagin_name);
        }
    }, [submitSocialForm.values.fb_campagin_name]);


    useEffect(() => {
       // getsocialfbcampignlistFromAPI()
        getfbcampaignFromAPI();

        console.log(fbcampaign);
    }, []);

    return (
        <>
            <Grid style={styleCss.commonpaddingtop}>
                <form onSubmit={submitSocialForm.handleSubmit}>
                    <Grid container item columnSpacing={2}>
                        <Grid item md={9} xs={12} sx={{ order: { xs: 2, md: 1 } }}>
                            <Grid container item columnSpacing={2}>
                                {isFacebookSelected && (
                                    <Grid item md={4} xs={12} sx={{ marginBottom: '15px' }}>
                                        <FacebookCampaign formikValues={submitSocialForm.values}
                                              socialfbcampigndetaillist={socialfbcampigndetaillist}
                                            formikSetFieldValue={submitSocialForm.setFieldValue} />
                                    </Grid>
                                )}
                                {/*
                            {isLinkedSelected && (
                                <Grid item md={4} xs={12} sx={{ marginBottom: '15px' }}>
                                    <LinkedInCampaign />
                                </Grid>
                            )}
                            {isTwitterSelected && (
                                <Grid item md={4} xs={12} sx={{ marginBottom: '15px' }}>
                                    <TwitterCampaign />
                                </Grid>
                            )}
                            

                            {isWhatsupSelected && (
                                <Grid item md={4} xs={12} sx={{ marginBottom: '15px' }}>
                                    <GoogleCampaign />
                                </Grid>
                            )}
                            */}
                                {isWhatsupSelected && (
                                    <Grid item md={4} xs={12} sx={{ marginBottom: '15px' }}>
                                        <WhatsupCampaign formikValues={submitSocialForm.values}
                                            formikSetFieldValue={submitSocialForm.setFieldValue} />
                                    </Grid>
                                )}
                                {isMailSelected && (
                                    <Grid item md={4} xs={12} sx={{ marginBottom: '15px' }}>
                                        <MailCampaign formikValues={submitSocialForm.values}
                                            formikSetFieldValue={submitSocialForm.setFieldValue} />
                                    </Grid>
                                )}
                                {isMessageunreadSelected && (
                                    <Grid item md={4} xs={12} sx={{ marginBottom: '15px' }}>
                                        <MessageCampaign formikValues={submitSocialForm.values}
                                            formikSetFieldValue={submitSocialForm.setFieldValue} />
                                    </Grid>
                                )}
                                {isvoicebotSelected && (
                                    <Grid item md={4} xs={12} sx={{ marginBottom: '15px' }}>
                                        <VoicebotCampaign formikValues={submitSocialForm.values}
                                            formikSetFieldValue={submitSocialForm.setFieldValue} />
                                    </Grid>
                                )}
                                {is_ivr_Selected && (
                                    <Grid item md={4} xs={12} sx={{ marginBottom: '15px' }}>
                                        <IvrCampaign formikValues={submitSocialForm.values}
                                            formikSetFieldValue={submitSocialForm.setFieldValue} />
                                    </Grid>
                                )}
                            </Grid>
                            {/*
                        <Grid container>
                            <Grid item md={12} xs={12} sx={{ marginBottom: '15px', marginTop: '20px' }}>
                                <Grid style={styleCss.CampaignBox}>
                                    <WorldMap />
                                </Grid>
                            </Grid>
                        </Grid>
                        */}
                        </Grid>


                        <Grid item md={3} xs={12} sx={{ order: { xs: 1, md: 2 } }}>

                            <Grid sx={{
                                width: '100%',
                                position: 'sticky',
                                top: '90px',
                                backgroundColor: '#fff',
                                boxSizing: 'border-box',
                            }}>
                                <Grid sx={styleCss.mediatypeRightBox}>
                                    <Grid md={12}>
                                        <Typography sx={{
                                            fontSize: '14px',
                                            fontWeight: 600,
                                            fontStretch: 'normal',
                                            fontStyle: 'normal',
                                            lineHeight: 'normal',
                                            letterSpacing: 'normal',
                                            textAlign: 'left',
                                            color: '#000',
                                            fontFamily: 'Inter',
                                            marginBottom: '13px'
                                        }}>Media Type</Typography>
                                    </Grid>
                                    <Grid style={{ width: '100%' }}>
                                        <Box
                                            sx={{
                                                justifyContent: 'space-between',
                                                display: 'grid',
                                                gridTemplateColumns: 'repeat(5, 1fr)',
                                                gap: '10px',
                                            }}
                                        >
                                            <Grid style={{
                                                padding: '10px 15px', borderRadius: '6px', border: 'solid 1px #000',
                                                textAlign: 'center'
                                            }} onClick={() => handleMediaType('facebook')} className={(isFacebookSelected) ? ('activemediatype') : ('')}>
                                                <img src={SocialIcon} />
                                            </Grid>
                                            {/*
                                        <Grid style={styleCss.socialBoxBorder} onClick={() => handleMediaType('linkedIn')} className={(isLinkedSelected) ? ('activemediatype') : ('')}>
                                            <img src={LinkedIcon} />
                                        </Grid>
                                     
                                        <Grid style={styleCss.socialBoxBorder}
                                            onClick={() => handleMediaType('twitter')}
                                            className={(isTwitterSelected) ? ('activemediatype') : ('')}>
                                            <img src={Xicon} />
                                        </Grid>
                                        
                                        <Grid style={styleCss.socialBoxBorder} onClick={() => handleMediaType('google')} className={(isGoogleSelected) ? ('activemediatype') : ('')}>
                                            <img src={GoogleIcon} />
                                        </Grid>
                                        */}
                                            <Grid style={styleCss.socialBoxBorder} onClick={() => handleMediaType('whatsup')} className={(isWhatsupSelected) ? ('activemediatype') : ('')}>
                                                <img src={WhatsupIcon} />
                                            </Grid>

                                            <Grid style={styleCss.socialBoxBorder} onClick={() => handleMediaType('mail')} className={(isMailSelected) ? ('activemediatype') : ('')}>
                                                <img src={MessageIcon1} />
                                            </Grid>
                                            <Grid style={styleCss.socialBoxBorder} onClick={() => handleMediaType('messageunread')} className={(isMessageunreadSelected) ? ('activemediatype') : ('')}>
                                                <img src={MessageIcon2} />
                                            </Grid>
                                            <Grid style={styleCss.socialBoxBorder} onClick={() => handleMediaType('voicebot')} className={(isvoicebotSelected) ? ('activemediatype') : ('')}>
                                                <img src={MessageIcon3} />
                                            </Grid>
                                            <Grid style={styleCss.socialBoxBorder} onClick={() => handleMediaType('ivr')} className={(is_ivr_Selected) ? ('activemediatype') : ('')}>
                                                <img src={MessageIcon4} />
                                            </Grid>
                                        </Box>
                                    </Grid>

                                    {(isFacebookSelected || isLinkedSelected || isTwitterSelected || isWhatsupSelected || isMailSelected || isMessageunreadSelected || isvoicebotSelected || is_ivr_Selected) && (
                                        <Grid md={12}>
                                            <Typography sx={{
                                                fontSize: '14px',
                                                fontWeight: 600,
                                                fontStretch: 'normal',
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                textAlign: 'left',
                                                color: '#000',
                                                fontFamily: 'Inter',
                                                marginBottom: '13px',
                                                marginTop: '15px',
                                            }}>Campaign Name</Typography>
                                        </Grid>
                                    )}

                                    {isFacebookSelected && (
                                        <Grid md={12} sx={{ marginBottom: '15px' }}>
                                            <Grid style={{ position: "relative" }}>
                                                <select 
                                                    className="form-select" 
                                                    name="fb_campagin_name" 
                                                    style={styleCss.drobDownstyle} 
                                                    value={submitSocialForm.values.fb_campagin_name} 
                                                    onChange={submitSocialForm.handleChange}
                                                >
                                                    <option value="">-select-</option>
                                                    {fbcampaign.length > 0 ? (
                                                        <>
                                                            {/* Print the length here */}
                                                            <p>FB Campaigns Available: {fbcampaign.length}</p>
                                                            {fbcampaign.map((item) => (
                                                                <option key={item.facebook_ad_posting_id} value={item.facebook_ad_posting_id}>
                                                                    {item.fb_campaign_name}
                                                                </option>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <option disabled>No fb pages Available</option>
                                                    )}
                                                </select>
                                                <img src={FacebookDropdown} style={styleCss.mediaIconstyle1} />
                                            </Grid>
                                        </Grid>
                                    )}


                                    {isLinkedSelected && (
                                        <Grid md={12} sx={{ marginBottom: '15px' }}>
                                            <Grid style={{ position: "relative" }}>
                                                <select className="form-select" style={styleCss.drobDownstyle}>
                                                    <option selected>Campaign Name 1</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </select>
                                                <img src={LinkedinDropdown} style={styleCss.mediaIconstyle} />
                                            </Grid>
                                        </Grid>
                                    )}

                                    {isTwitterSelected && (
                                        <Grid md={12} sx={{ marginBottom: '15px' }}>
                                            <Grid style={{ position: "relative" }}>
                                                <select className="form-select" style={styleCss.drobDownstyle}>
                                                    <option selected>Campaign Name 1</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </select>
                                                <img src={XiconDropdown} style={styleCss.mediaIconstyle} />
                                            </Grid>
                                        </Grid>
                                    )}
                                    {isGoogleSelected && (
                                        <Grid md={12} sx={{ marginBottom: '15px' }}>
                                            <Grid style={{ position: "relative", }}>
                                                <TextField
                                                    id="outlined-basic"
                                                    label=""
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    sx={{
                                                        '& .MuiInputBase-input': {
                                                            padding: '8px 12px 8px 32px',
                                                            fontFamily: 'Inter',
                                                            fontSize: '15px',
                                                            fontWeight: 'normal',
                                                            letterSpacing: 'normal',
                                                            textAlign: 'left',
                                                            color: '#555',
                                                        },
                                                    }}
                                                />
                                                <img src={GoogleIcon} style={styleCss.googleIconStyle} />
                                            </Grid>
                                        </Grid>
                                    )}

                                    {isWhatsupSelected && (
                                        <Grid md={12} sx={{ marginBottom: '15px' }}>
                                            <Grid style={{ position: "relative", }}>
                                                <TextField
                                                    name="wb_campagin_name" value={submitSocialForm.values.wb_campagin_name} onChange={submitSocialForm.handleChange}
                                                    id="outlined-basic"
                                                    label=""
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    sx={{
                                                        '& .MuiInputBase-input': {
                                                            padding: '8px 12px 8px 32px',
                                                            fontFamily: 'Inter',
                                                            fontSize: '15px',
                                                            fontWeight: 'normal',
                                                            letterSpacing: 'normal',
                                                            textAlign: 'left',
                                                            color: '#555',
                                                        },
                                                    }}
                                                />
                                                <img src={WhatsupIcon} style={styleCss.googleIconStyle} />
                                            </Grid>
                                        </Grid>
                                    )}
                                    {isMailSelected && (
                                        <Grid md={12} sx={{ marginBottom: '15px' }}>
                                            <Grid style={{ position: "relative", }}>
                                                <TextField
                                                    id="outlined-basic"
                                                    name="mail_campagin_name" value={submitSocialForm.values.mail_campagin_name} onChange={submitSocialForm.handleChange}
                                                    label=""
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    sx={{
                                                        '& .MuiInputBase-input': {
                                                            padding: '8px 12px 8px 32px',
                                                            fontFamily: 'Inter',
                                                            fontSize: '15px',
                                                            fontWeight: 'normal',
                                                            letterSpacing: 'normal',
                                                            textAlign: 'left',
                                                            color: '#555',
                                                        },
                                                    }}
                                                />
                                                <img src={MessageIcon1} style={styleCss.googleIconStyle} />
                                            </Grid>
                                        </Grid>
                                    )}
                                    {isMessageunreadSelected && (
                                        <Grid md={12} sx={{ marginBottom: '15px' }}>
                                            <Grid style={{ position: "relative", }}>
                                                <TextField
                                                    id="outlined-basic"
                                                    name="sms_campagin_name" value={submitSocialForm.values.sms_campagin_name} onChange={submitSocialForm.handleChange}
                                                    label=""
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    sx={{
                                                        '& .MuiInputBase-input': {
                                                            padding: '8px 12px 8px 32px',
                                                            fontFamily: 'Inter',
                                                            fontSize: '15px',
                                                            fontWeight: 'normal',
                                                            letterSpacing: 'normal',
                                                            textAlign: 'left',
                                                            color: '#555',
                                                        },
                                                    }}
                                                />
                                                <img src={MessageIcon2} style={styleCss.googleIconStyle} />
                                            </Grid>
                                        </Grid>
                                    )}

                                    {isvoicebotSelected && (
                                        <Grid md={12} sx={{ marginBottom: '15px' }}>
                                            <Grid style={{ position: "relative", }}>
                                                <TextField
                                                    id="outlined-basic"
                                                    name="voicebot_campagin_name" value={submitSocialForm.values.voicebot_campagin_name} onChange={submitSocialForm.handleChange}
                                                    label=""
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    sx={{
                                                        '& .MuiInputBase-input': {
                                                            padding: '8px 12px 8px 32px',
                                                            fontFamily: 'Inter',
                                                            fontSize: '15px',
                                                            fontWeight: 'normal',
                                                            letterSpacing: 'normal',
                                                            textAlign: 'left',
                                                            color: '#555',
                                                        },
                                                    }}
                                                />
                                                <img src={VoiceIcon} style={styleCss.googleIconStyle} />
                                            </Grid>
                                        </Grid>
                                    )}

                                    {is_ivr_Selected && (
                                        <Grid md={12} sx={{ marginBottom: '15px' }}>
                                            <Grid style={{ position: "relative", }}>
                                                <TextField
                                                    id="outlined-basic"
                                                    name="ivr_campagin_name" value={submitSocialForm.values.ivr_campagin_name} onChange={submitSocialForm.handleChange}
                                                    label=""
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    sx={{
                                                        '& .MuiInputBase-input': {
                                                            padding: '8px 12px 8px 32px',
                                                            fontFamily: 'Inter',
                                                            fontSize: '15px',
                                                            fontWeight: 'normal',
                                                            letterSpacing: 'normal',
                                                            textAlign: 'left',
                                                            color: '#555',
                                                        },
                                                    }}
                                                />
                                                <VoiceChatIcon style={styleCss.googleIconStyle} />

                                            </Grid>
                                        </Grid>
                                    )}



                                    <Grid md={12}>
                                        <Typography sx={{
                                            fontSize: '14px',
                                            fontWeight: 600,
                                            fontStretch: 'normal',
                                            fontStyle: 'normal',
                                            lineHeight: 'normal',
                                            letterSpacing: 'normal',
                                            textAlign: 'left',
                                            color: '#000',
                                            fontFamily: 'Inter',
                                            marginBottom: '10px',
                                            marginTop: '10px'
                                        }}>
                                            Overall Budget
                                        </Typography>

                                        Total Amount: {submitSocialForm.values.total_amount}

                                        {/*    
                                    <SliderRange
                                    min={100}
                                    max={100000}
                                        valueLabelDisplay="auto"
                                        aria-labelledby="range-slider"
                                        name="total_amount"
                                        value={submitSocialForm.values.total_amount}
                                        onChange={(event, newValue) => handleTotalAmountChange(event, newValue)}
                                    />
                                    */}
                                    </Grid>
                                    <Grid md={12}>
                                        <Typography sx={{
                                            fontSize: '14px',
                                            fontWeight: 600,
                                            fontStretch: 'normal',
                                            fontStyle: 'normal',
                                            lineHeight: 'normal',
                                            letterSpacing: 'normal',
                                            textAlign: 'left',
                                            color: '#000',
                                            fontFamily: 'Inter',
                                            marginBottom: '10px',
                                            marginTop: '13px',
                                        }}>
                                            Date
                                        </Typography>
                                    </Grid>

                                    <Grid md={12} sx={{ marginTop: '20px' }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ width: '100%' }}>
                                            <DatePicker
                                                label="From Date"
                                                name="from_date"
                                                value={submitSocialForm.values.from_date || dayjs()} // Ensure it defaults to a valid Dayjs object
                                                onChange={handleDateChangefrom}
                                                renderInput={(params) => <TextField {...params} sx={{ width: '100%' }} />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid md={12} sx={{ marginTop: '20px' }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ width: '100%' }}>
                                            <DatePicker
                                                label="To Date"
                                                name="to_date"
                                                value={submitSocialForm.values.to_date || dayjs()} // Ensure it defaults to a valid Dayjs object
                                                onChange={handleDateChangeto}
                                                renderInput={(params) => <TextField {...params} sx={{ width: '100%' }} />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid md={12} sx={{ marginTop: '20px', marginBottom: '10px' }}>
                                        <Button
                                            variant="contained"
                                            sx={{
                                                fontFamily: 'Inter',
                                                fontSize: '16px',
                                                fontWeight: 'bold',
                                                textAlign: 'center',
                                                color: '#fff',
                                                borderRadius: '6px',
                                                backgroundColor: '#16cc13',
                                                textTransform: 'capitalize',
                                                width: '100%',
                                                padding: '13px 16px',
                                            }}
                                            type="button" // Specify the type directly inside the Button component
                                            onClick={handleFormSubmit} // Assign the click handler function
                                        >
                                            Get Reach Details
                                        </Button>

                                        {showAdditionalInput && (
                                            <>
                                                <TextField
                                                    type="text"
                                                    label="Screen Name"
                                                    value={additionalInputValue}
                                                    onChange={(e) => setAdditionalInputValue(e.target.value)}
                                                    sx={{ mt: 2, width: '100%' }}
                                                />
                                                <Button
                                                    variant="contained"
                                                    sx={{
                                                        fontFamily: 'Inter',
                                                        fontSize: '16px',
                                                        fontWeight: 'bold',
                                                        textAlign: 'center',
                                                        color: '#fff',
                                                        borderRadius: '6px',
                                                        backgroundColor: '#1E90FF',
                                                        textTransform: 'capitalize',
                                                        width: '100%',
                                                        padding: '13px 16px',
                                                        mt: 2
                                                    }}
                                                    type="button" // Change to button to handle custom save logic
                                                    onClick={handleSaveAdditionalInput} // Handle the save button click
                                                >
                                                    Save
                                                </Button>
                                            </>
                                        )}
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </form>
            </Grid >
        </>
    )
}

export default Mediatype